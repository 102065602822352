import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { colors, REGEXP, routes } from 'constants/index'
import { setCountryStateValue, setClientAnswer } from 'store/actions/search'
import { TextField, Button, Checkbox } from 'components'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'utils/hooks'
//import classNames from 'classnames'
import MainSearch from './MainSearch'
import StatesAutocomplete from './StatesAutocomplete'
import { navigateTo } from '../../store/actions/navigation'

const useStyles = makeStyles({
  checkbox: {
    color: colors.WHITE,
  },
  label: {
    fontSize: 15,
    color: colors.WHITE,
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 200,
    },
  },
  link: {
    fontSize: 15,
    color: colors.PRIMARY.COLOR[20],
  },
  wrapper: {
    position: 'relative',
    fontFamily: 'Roboto',
    marginTop: 25,
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      width: 'calc(100vw - 30px)',
    },
  },
  stateWrapper: {
    display: 'flex',
    marginTop: 15,
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      width: 'calc(100vw - 30px)',
    },
  },
  startPageBtn: {
    cursor: 'pointer',
    height: 55,
    background: colors.PRIMARY.COLOR[50],
    borderRadius: '0 5px 5px 0',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.PRIMARY.COLOR[0],
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.WHITE,
    },
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
      borderRadius: 5,
    },
  },
  notched: {
    border: 0,
  },
  searchRoot: {
    height: 120,
    padding: 9,
    borderRadius: '4px 4px 4px 4px',
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 535,
    },
    '@media(max-width:768px)': {
      width: '100%',
      marginBottom: 12,
      borderRadius: 4,
    },
  },
  buttonTypeSearch: {
    top: 5,
    left: 276,
    padding: '6px 24px',
    fontSize: '10px',
    height: 20,
    minHeight: 20,
    width: 70,
    '@media(max-width:1279px)': {
      left: 174,
    },
    '@media(max-width:939px)': {
      left: 130,
    },
    '@media(max-width:768px)': {
      left: 'unset',
      right: 12,
      top: 17,
    },
    '&:hover': {
      backgroundColor: colors.PRIMARY.COLOR[60],
      color: colors.WHITE,
    },
  },
})

const SearchByAi = props => {
  const { location } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const query = useQuery()
  const countryStateValue = useSelector(state => state.search.countryStateValue)
  const state = query.get('state')
  const [checked, setChecked] = useState(window.sessionStorage.isDisclaimerChecked === 'true')
  const [searchName, setSearchName] = useState('')
  const [currentSearchState, setCurrentSearchState] = useState(countryStateValue)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setCurrentSearchState(countryStateValue)
    if (REGEXP.matchToSearchRoute.test(location.pathname)) {
      if (state) {
        dispatch(setCountryStateValue(state))
      }
    }
  }, [location.pathname, countryStateValue, state, dispatch])

  const onChangeSearch = value => {
    setSearchName(value)
  }

  const onSubmit = () => {
    const params = {
      question: searchName.trim(),
      isDisclaimerChecked: checked,
      state: currentSearchState,
    }
    let queryParams = ''
    for (const key in params) {
      if (params[key]) {
        queryParams += queryParams ? '&' : '?'
        queryParams += `${key}=${params[key]}`
      }
    }
    window.top.location.href = `${routes.AI_CHAT}${queryParams}`
  }

  return (
    <>
      <div className={classes.wrapper}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          placeholder="In your own words, tell me about your legal question?"
          onChange={e => onChangeSearch(e.target.value)}
          value={searchName}
          inputProps={{
            className: classes.headerInputRoot,
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.notched,
              root: classes.searchRoot,
            },
          }}
        />
        <div className={classes.stateWrapper}>
          {!isMobile && (
            <Checkbox
              labelTextClasses={{ root: classes.label }}
              classes={{ root: classes.checkbox }}
              checked={checked}
              label={
                <span>
                  I reviewed and agreed with{' '}
                  <a target="_blank" className={classes.link} href="https://xira.com/p/ai_disclaimer/" rel="noreferrer">
                    terms and conditions
                  </a>
                </span>
              }
              onClick={e => {
                setChecked(e.target.checked)
              }}
            />
          )}
          <StatesAutocomplete
            type="startPage"
            setCurrentSearchState={setCurrentSearchState}
            currentSearchState={currentSearchState}
          />
          <Button
            variant="primary"
            onClick={onSubmit}
            className={classes.startPageBtn}
            disabled={!checked || !searchName.trim() || !currentSearchState}
          >
            Submit
          </Button>
          {isMobile && (
            <Checkbox
              labelTextClasses={{ root: classes.label }}
              classes={{ root: classes.checkbox }}
              checked={checked}
              label={
                <span>
                  I reviewed and agreed with{' '}
                  <a className={classes.link} href="https://xira.com/p/ai_disclaimer/">
                    terms and conditions
                  </a>
                </span>
              }
              onClick={e => {
                window.sessionStorage.setItem('isDisclaimerChecked', e.target.checked)
                setChecked(e.target.checked)
              }}
            />
          )}
        </div>
        <MainSearch />
      </div>
    </>
  )
}

export default withRouter(SearchByAi)
