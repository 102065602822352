import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { TextField, SvgIcon } from 'components'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { ReactComponent as IconArrowDown } from 'static/ic_arrow_down.svg'
import { colors } from '../../constants'

const useStyles = makeStyles({
  root: {
    width: 200,
    borderRadius: '0 0 0 0',
    '&.MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 0,
      },
    },
    '@media(max-width:767px)': {
      width: 'calc(100vw - 30px)',
    },
  },
  rootStartPage: {
    height: 55,
    width: 243,
    '@media(max-width:1023px) and (min-width:768px)': {
      width: 175,
    },
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
      width: 'calc(100vw - 30px)',
      borderRadius: 4,
      marginBottom: 12,
    },
  },
  headerPage: {
    height: 32,
    padding: '0 !important',
    border: `solid 1px ${colors.BASIC.COLOR[10]}`,
    borderRightWidth: 0,
    '&:hover': {
      border: `solid 1px ${colors.BASIC.COLOR[20]}`,
    },
  },
  startPage: {
    borderWidth: 0,
    '@media(max-width:767px)': {
      flexWrap: 'wrap',
    },
  },
  header: {
    borderWidth: 0,
  },
  arrowIcon: {
    width: 24,
    height: 24,
  },
  '@media(max-width:939px)': {
    headerPage: {
      width: 145,
    },
  },
  '@media(max-width:767px)': {
    headerPage: {
      width: '100%',
      marginBottom: 16,
      borderRadius: 4,
      height: 54,
    },
  },
  input: {
    paddingLeft: '12px !important',
  },
})

const Arrow = () => {
  const classes = useStyles()
  return <SvgIcon component={IconArrowDown} viewBox="0 0 16 16" className={classes.arrowIcon} />
}

const StatesAutocomplete = ({ type, setCurrentSearchState, currentSearchState, isBordered = false }) => {
  const classes = useStyles()
  const states = useSelector(state => state.profileCreation.states)

  const autocompleteValue = () => {
    return { label: currentSearchState }
  }

  const renderStateOption = (option, { inputValue }) => {
    const matches = match(option.label, inputValue)
    const parts = parse(option.label, matches)
    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    )
  }
  return (
    <Autocomplete
      id="autocomplete-state"
      closeIcon={null}
      popupIcon={Arrow()}
      autoHighlight
      classes={{
        inputRoot: classNames(classes.root, {
          [classes.rootStartPage]: type === 'startPage',
          [classes.headerPage]: type === 'header',
        }),
      }}
      onChange={(e, value) => value && setCurrentSearchState(value.label)}
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={option => option.label}
      filterOptions={(options, { inputValue }) =>
        options.filter(option => option.label.toLowerCase().startsWith(inputValue.toLowerCase()))
      }
      options={states}
      value={autocompleteValue()}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder="States"
          style={{ border: isBordered ? `1px solid ${colors.BASIC.COLOR[30]}` : '0 0 0 0' }}
          InputProps={{
            ...params.InputProps,
            classes: { notchedOutline: classes[type], input: classes.input },
          }}
        />
      )}
      renderOption={renderStateOption}
    />
  )
}

export default StatesAutocomplete
