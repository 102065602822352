import * as CONSTANTS from 'store/constants/search'

export const initialState = {
  searchString: '',
  proAccountIsLoading: false,
  defaultSearchParams: {},
  random_seed: Math.random(),
  proAccount: {
    id: null,
    degrees: [],
    bio: '',
    languages: [],
    licenses: [],
    practiceAreas: [],
    rating: null,
    socialLinks: [],
    photo: '',
    firstName: '',
    lastName: '',
    video: '',
    reviews: null,
    connectionWithProExists: null,
    isRecommended: false,
    active_calendar: false,
  },
  proAccountAvailableTime: {},
  proAccountAvailableTimeIsLoading: false,
  searchParams: {
    limit: 20,
    offset: 0,
  },
  filters: {
    averageRating: null,
    minHourlyRate: 0,
    maxHourlyRate: 1000,
    startFreeTimeValue: 0,
    endFreeTimeValue: 13,
    contingency: false,
    fixedPrice: false,
    consultationCost: false,
    languages: 'English',
    state_search: false,
  },
  sorter: '',
  profiles: [],
  profilesCount: 0,
  averagePrice: '',
  autocompletePracticeArray: [],
  clientQuestion: '',
  answerIsLoading: false,
  practiceAreaValue: '',
  practiceSearchType: +window.sessionStorage.getItem('searchType') || 0,
  practiceSearchName: window.sessionStorage.getItem('searchName') || '',
  practiceAreaParam: '',
  practiceAreasListIsLoading: false,
  countryStateValue: '',
  autocompleteArray: [],
  searchListTitle: 'Search',
  duration: 30,
  profileListIsLoading: false,
  profileListIsLazyLoading: false,
  profilesEmptyMsg: {},
  practiceAreaSearchObject: {},
  defaultSearchFilters: {
    averageRating: null,
    minHourlyRate: 0,
    maxHourlyRate: 1000,
    startFreeTimeValue: 0,
    endFreeTimeValue: 13,
    contingency: false,
    fixedPrice: false,
    consultationCost: false,
    languages: 'English',
    state_search: false,
  },
}

const search = (state = initialState, action = {}) => {
  switch (action.type) {
    case CONSTANTS.GET_PRO_ACCOUNT_START:
      return {
        ...state,
        proAccountIsLoading: true,
      }
    case CONSTANTS.GET_PRO_ACCOUNT_END:
      return {
        ...state,
        proAccountIsLoading: false,
        proAccount: action.payload,
      }

    case CONSTANTS.GET_PRO_ACCOUNT_ERROR:
      return {
        ...state,
        proAccountIsLoading: false,
      }
    case CONSTANTS.GET_PRO_ACCOUNT_AVAILABLE_TIME_START:
      return {
        ...state,
        proAccountAvailableTimeIsLoading: true,
      }
    case CONSTANTS.GET_PRO_ACCOUNT_AVAILABLE_TIME_END:
      return {
        ...state,
        proAccountAvailableTime: action.payload,
        proAccountAvailableTimeIsLoading: false,
      }
    case CONSTANTS.SET_AUTOCOMPLETE_PRACTICE_ARRAY:
      return {
        ...state,
        autocompletePracticeArray: action.payload,
      }
    case CONSTANTS.GET_PROFILE_LIST_START:
      return {
        ...state,
        profileListIsLoading: true,
      }
    case CONSTANTS.GET_PROFILE_LIST_LAZY_LOAD_START:
      return {
        ...state,
        profileListIsLazyLoading: true,
      }
    case CONSTANTS.GET_PRACTICE_AREA_MATCHES_START:
      return {
        ...state,
        practiceAreasListIsLoading: true,
      }
    case CONSTANTS.GET_PRACTICE_AREA_MATCHES_END:
      return {
        ...state,
        practiceAreasListIsLoading: false,
      }
    case CONSTANTS.GET_PROFILE_LIST_END:
      return {
        ...state,
        proAccountIsLoading: false,
        profileListIsLoading: false,
        profileListIsLazyLoading: false,
        profiles: action.payload,
        profilesCount: action.profilesCount,
        profilesEmptyMsg: action.profilesEmptyMsg,
      }
    case CONSTANTS.RESET_UPLOAD_OFFSET:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          offset: 0,
        },
      }
    case CONSTANTS.INCREASE_UPLOAD_OFFSET:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          offset: state.searchParams.offset + state.searchParams.limit,
        },
      }
    case CONSTANTS.RESET_FILTERS:
      return {
        ...state,
        filters: state.defaultSearchFilters,
        sorter: '',
      }
    case CONSTANTS.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      }
    case CONSTANTS.SET_SORTER:
      return {
        ...state,
        sorter: action.value,
      }
    case CONSTANTS.GOT_AVERAGE_PRICE:
      return {
        ...state,
        averagePrice: action.payload.average_price,
      }
    case CONSTANTS.SET_COUNTRY_STATE_VALUE:
      return {
        ...state,
        countryStateValue: action.payload,
      }

    case CONSTANTS.SET_PRACTICE_AREA_VALUE:
      return {
        ...state,
        practiceAreaValue: action.payload,
      }
    case CONSTANTS.SET_PRACTICE_SEARCH_TYPE:
      return {
        ...state,
        practiceSearchType: action.payload,
      }
    case CONSTANTS.SET_PRACTICE_SEARCH_NAME:
      return {
        ...state,
        practiceSearchName: action.payload,
      }
    case CONSTANTS.SET_PRACTICE_AREA_PARAM:
      return {
        ...state,
        practiceAreaParam: action.payload,
      }
    case CONSTANTS.SET_PRACTICE_AREA_SEARCH_OBJECT:
      return {
        ...state,
        practiceAreaSearchObject: action.payload,
        filters: {
          ...state.filters,
          state_search: !action.payload.is_federal,
        },
      }
    case CONSTANTS.SET_AUTOCOMPLETE_ARRAY:
      return {
        ...state,
        autocompleteArray: action.payload,
      }
    case CONSTANTS.SET_SEARCH_LIST_TITLE:
      return {
        ...state,
        searchListTitle: action.payload,
      }
    case CONSTANTS.SET_MEETING_DURATION:
      return {
        ...state,
        duration: action.payload,
      }
    case CONSTANTS.SET_DEFAULT_SEARCH_PARAMS:
      return {
        ...state,
        defaultSearchParams: action.payload,
      }
    case CONSTANTS.SET_RANDOM_SEED:
      return {
        ...state,
        random_seed: action.payload,
      }
    case CONSTANTS.SET_DEFAULT_SEARCH_FILTER:
      return {
        ...state,
        defaultSearchFilters: {
          ...state.defaultSearchFilters,
          ...action.payload,
        },
      }
    case CONSTANTS.SET_CLIENT_QUESTION:
      return {
        ...state,
        clientQuestion: action.payload,
      }
    case CONSTANTS.SET_ANSWER:
      return {
        ...state,
        aiAnswer: action.payload,
      }
    case CONSTANTS.SET_IS_LOADING:
      return {
        ...state,
        answerIsLoading: action.payload,
      }
    default:
      return state
  }
}

export default search
